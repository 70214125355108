@media (prefers-color-scheme: dark) {

		:root {
				--background-color: #000;
				--text-color: #ccc;
				--link-color: #7CA8DA;
				--visited-link-color: #8CABFF;
				--form-background-color: #333;
				--form-text-color: #fff;
		}
}
