body {
		background-color: var(--background-color);
		color: var(--text-color);
		margin: auto;
		max-width: 100ch;
		padding: 5px;
}

a:link{
		color: var(--link-color);
}

a:visited{
		color: var(--visited-link-color);
}

img {
		max-width: 100%;
}

.h-card a {
		display: none;
}

.h-card h2 {
		display: inline;
		font-size: 100%;
		font-weight: normal;
}

.h-card p {
		display: inline;
}

.h-card img {
		display: none;
}

.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
