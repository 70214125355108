input[type="text"],
input[type="email"],
textarea
{
		display: block;
		width: 400px;
}

input[type="email"],
input[type="search"],
input[type="text"],
textarea
{		
		background-color: var(--form-background-color);
		color: var(--form-text-color);
}
 
textarea
{
		height: 100px;
}
